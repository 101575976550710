import { Box, Breadcrumbs, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { NavigateNextOutlined } from "@mui/icons-material";

function DashboardMainSection({
  CurrentLocation,
  component1,
  component2,
  component3,
}) {
  return (
    <Box
      component="main"
      sx={{
        // backgroundColor: "rgb(51, 51, 51)",
        width: "100%",
        height: "100vh",
        display: "flex",
        // padding: 2,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          width: "95%",
          height: "95%",
          background: "#232222",
          border: "4px solid #3d3d3d",
          borderRadius: "10px",
          overflowY: "scroll",
        }}
      >
        <Box margin={"5px 40px"} pb={1}>
          <Breadcrumbs
            aria-label="breadcrumb"
            separator={
              <NavigateNextOutlined fontSize="medium" color="primary" />
            }
          >
            <Link
              underline="hover"
              to="/dashboard"
              style={{ textDecoration: "none" }}
            >
              <Typography variant="h5" color="primary.dark">
                Dashboard
              </Typography>
            </Link>

            <Typography variant="h5" color="primary">
              {CurrentLocation}
            </Typography>
          </Breadcrumbs>
        </Box>
        {component1}
        {component2}
        {component3}
      </Box>
    </Box>
  );
}

export default DashboardMainSection;
