import { Box, Typography, LinearProgress } from "@mui/material";

const DashboardCard = ({
  title,
  progress,
  progressColor,
  description,
  Icon,
}) => {
  return (
    <Box
      sx={{
        cursor: "pointer",
        backgroundColor: "#313131",
        padding: "16px",
        borderRadius: "8px",
        boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
        textAlign: "center",
        position: "relative", // Positioning the icon relative to the card
        overflow: "hidden",
        "&:hover": {
          ".rotate-on-hover": {
            transform: "rotate(-40deg)", // Rotation applied on hover
          },
          ".text-color-on-hover": {
            color: "secondary.dark", // Change text color on hover
          },
        },
      }}
    >
      <Box
        className="rotate-on-hover"
        sx={{
          position: "absolute",
          top: "3px",
          right: "20px",
          rotate: "20deg",
          transition: "transform 0.3s ease-in-out", // Smooth transition for hover effect
          transform: "rotate(0deg)", // Default state without rotation
        }}
      >
        <Icon
          sx={{
            zIndex: "-20",
            fontSize: 50,
            color: "rgba(255, 255, 255, 0.2)",
          }}
        />
      </Box>
      <Typography
        className="text-color-on-hover"
        variant="h5"
        gutterBottom
        color="white"
        mt={5}
        sx={{
          cursor: "pointer",
        }}
      >
        {title}{" "}
      </Typography>{" "}
      <Typography variant="body2" color="white">
        {description}
      </Typography>
      <LinearProgress
        variant="determinate"
        value={progress}
        sx={{
          height: "8px",
          // borderRadius: "5px",
          backgroundColor: "#424656",
          marginY: "14px",
          "& .MuiLinearProgress-bar": {
            backgroundColor: progressColor, // Apply progressColor to the bar
          },
        }}
      />
    </Box>
  );
};

export default DashboardCard;
