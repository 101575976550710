import React, { useEffect, useState } from "react";
import MiniDrawer from "./components/Drawer";
import DashboardMainSection from "./components/DashboardMainSection";
import { Box } from "@mui/material";
import DashboardHeroSection from "./components/DashboardHome/DashboardHeroSection";
import ProjectStatus from "./components/DashboardHome/ProjectStatus";
import ProjectTeam from "./components/DashboardHome/ProjectTeam";
import axios from "axios";

function DashboardScreen() {
  const [userName, setUserName] = useState("Guest");
  const [userPercentages, setUserPercentages] = useState({
    ContractPhasePercentage: 0,
    DesignPhasePercentage: 0,
    FinalizeDesignPercentage: 0,
    ConstructionPhasePercentage: 0,
  });
  const [teamMembers, setTeamMembers] = useState([]);

  useEffect(() => {
    const userDetails = JSON.parse(localStorage.getItem("userDetails") || "{}");

    if (userDetails.username) {
      setUserName(userDetails.username);
    }

    if (userDetails) {
      setUserPercentages({
        ContractPhasePercentage: userDetails.ContractPhasePercentage || 0,
        DesignPhasePercentage: userDetails.DesignPhasePercentage || 0,
        FinalizeDesignPercentage: userDetails.FinalizeDesignPercentage || 0,
        ConstructionPhasePercentage:
          userDetails.ConstructionPhasePercentage || 0,
      });

      axios
        .get(
          `${process.env.REACT_APP_SERVER_DOMAIN}/api/users/${userDetails.id}/teamMembers`
        )
        .then((response) => {
          const members = [
            { name: response.data.name1, role: response.data.role1 },
            { name: response.data.name2, role: response.data.role2 },
            { name: response.data.name3, role: response.data.role3 },
            { name: response.data.name4, role: response.data.role4 },
          ].filter((member) => member.name); // Filter out empty entries
          setTeamMembers(members);
        })
        .catch((error) => {
          console.error("Error fetching team members:", error);
        });
    }
  }, []);

  return (
    <Box
      sx={{
        width: "100vw",
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <MiniDrawer CurrentLocation={"DASHBOARD"} userName={userName} />
      <DashboardMainSection
        CurrentLocation={"Home"}
        component1={<DashboardHeroSection />}
        component2={<ProjectStatus percentages={userPercentages} />}
        component3={<ProjectTeam teamMembers={teamMembers} />}
      />
    </Box>
  );
}

export default DashboardScreen;
