import { Box, Typography, Grid } from "@mui/material";
import MailIcon from "@mui/icons-material/Mail";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import StatusCard from "./StatusCard";

const ProjectStatus = ({ percentages }) => {
  console.log("Received percentages in ProjectStatus:", percentages);  // Debugging

  const stepsData = [
    {
      name: "Contract Phase",
      progress: percentages.ContractPhasePercentage,
      icon: <MailIcon sx={{ fontSize: 18, color: "#58D68D", mr: 1 }} />,
      number: `${percentages.ContractPhasePercentage}%`,
      color: "#8BC34A", // Contract Phase
    },
    {
      name: "Design Phase",
      progress: percentages.DesignPhasePercentage,
      icon: <MailIcon sx={{ fontSize: 18, color: "#36A2EB", mr: 1 }} />,
      number: `${percentages.DesignPhasePercentage}%`,
      color: "#03A9F4", // Design Phase
    },
    {
      name: "Finalized Design",
      progress: percentages.FinalizeDesignPercentage,
      icon: <MailIcon sx={{ fontSize: 18, color: "#FFCE56", mr: 1 }} />,
      number: `${percentages.FinalizeDesignPercentage}%`,
      color: "#3F51B5", // Finalized Design
    },
    {
      name: "Construction Phase",
      progress: percentages.ConstructionPhasePercentage,
      icon: <MailIcon sx={{ fontSize: 18, color: "#4BC0C0", mr: 1 }} />,
      number: `${percentages.ConstructionPhasePercentage}%`,
      color: "#9C27B0", // Construction Phase
    },
  ];

  return (
    <Box>
      <Grid container px={2} py={1} display={"flex"} justifyContent={"center"}>
        <Grid item xs={12} px={2}>
          <Typography
            variant="h5"
            width="100%"
            textAlign="left"
            gutterBottom
            display="flex"
            alignItems="center"
          >
            <AssessmentOutlinedIcon
              fontSize="large"
              sx={{ color: `secondary.dark`, mr: 1 }}
            />
            <Box component="span" sx={{ color: `secondary.dark`, fontWeight: "bold" }}>
              PROJECT
            </Box>
            <Box component="span" sx={{ color: `primary.dark`, marginLeft: 1, fontWeight: "500" }}>
              REPORT
            </Box>
          </Typography>
        </Grid>
        {stepsData.map((step, index) => (
          <Grid item xs={12} sm={6} md={3} key={index} px={2} py={1}>
            <StatusCard
              icon={step.icon}
              number={step.number}
              name={step.name}
              progress={step.progress}
              progressColor={step.color}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default ProjectStatus;
