import React, { useEffect, useState } from "react";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import { Box, CircularProgress, Typography } from "@mui/material";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import axios from "axios";
import "@react-pdf-viewer/core/lib/styles/index.css"; // Import core styles
import "@react-pdf-viewer/default-layout/lib/styles/index.css"; // Import default layout styles

function PDFviewer({ username }) {
  const [docUrl, setDocUrl] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Initialize the default layout plugin for PDFs
  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    renderToolbar: (Toolbar) => (
      <Toolbar>{renderDefaultToolbar(transform)}</Toolbar>
    ),
  });

  const { renderDefaultToolbar } =
    defaultLayoutPluginInstance.toolbarPluginInstance;

  const transform = (slot) => {
    const { Zoom } = slot;

    return {
      ...slot,
      Zoom: () => <Zoom levels={[0.4, 0.8, 1.2, 1.6, 2.4, 3.2]} />,
      Download: () => null, // Disable download button
      Print: () => null, // Disable print button
    };
  };

  useEffect(() => {
    const controller = new AbortController(); // Initialize AbortController to manage request cancellation

    if (username) {
      // Fetch the last presentation based on the username
      axios
        .get(
          `${process.env.REACT_APP_SERVER_DOMAIN}/api/presentations/getLastPresentation/${username}`,
          {
            signal: controller.signal, // Attach the signal to the Axios request
          }
        )
        .then((response) => {
          if (response.data.presentation) {
            setDocUrl(response.data.presentation.link); // Set the presentation URL
          } else {
            setError("No presentation found...");
          }
        })
        .catch((error) => {
          if (axios.isCancel(error)) {
            console.log("Request canceled", error.message);
          } else {
            console.error("Error fetching last presentation:", error);
            setError("Error fetching presentation");
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }

    return () => {
      controller.abort(); // Abort the request if the component unmounts
    };
  }, [username]);

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        style={{ height: "100vh" }}
      >
        <CircularProgress size={100} thickness={5} />
        <Typography variant="h6" style={{ marginLeft: "16px" }}>
          Loading presentation...
        </Typography>
      </Box>
    );
  }

  if (error) {
    return <Typography variant="h6">{error}</Typography>;
  }

  if (!docUrl) {
    return <Typography variant="h6">No presentation available...</Typography>;
  }

  // Check if the document is a PDF or DOCX based on the file extension
  const isPDF = docUrl.endsWith(".pdf");

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      style={{ height: "100vh", padding: "0px" }}
    >
      {isPDF ? (
        // If the document is a PDF, use the PDF viewer
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            style={{
              border: "1px solid rgba(0, 0, 0, 0.3)",
              overflow: "auto",
              width: "100%",
              height: "100%",
              padding: "10px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              borderRadius: "8px",
            }}
          >
            <Viewer
              fileUrl={docUrl} // Use the fetched PDF URL here
              plugins={[defaultLayoutPluginInstance]} // Include the plugin
              renderLoader={(percentages) => (
                <div
                  style={{
                    width: "100%",
                    height: "100vh",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CircularProgress
                    variant="determinate"
                    value={Math.round(percentages)}
                    size={100}
                    thickness={5}
                    style={{ marginBottom: "16px" }}
                  />
                  <Typography variant="h6">
                    Loading PDF... {Math.round(percentages)}%
                  </Typography>
                </div>
              )}
            />
          </Box>
        </Worker>
      ) : (
        // If the document is a DOCX, use the DOC viewer
        <DocViewer
          documents={[{ uri: docUrl }]} // Pass the presentation URL (PDF or DOCX)
          pluginRenderers={DocViewerRenderers}
          style={{ width: "100%", height: "100%" }}
        />
      )}
    </Box>
  );
}

export default PDFviewer;
