import React, { useEffect, useState } from "react";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import { Box, CircularProgress, Typography } from "@mui/material";
import axios from "axios";
import "@react-pdf-viewer/core/lib/styles/index.css"; // Import core styles
import "@react-pdf-viewer/default-layout/lib/styles/index.css"; // Import default layout styles

function PDFviewer({ username }) {
  const [pdfUrl, setPdfUrl] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Initialize the default layout plugin with custom toolbar
  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    renderToolbar: (Toolbar) => (
      <Toolbar>{renderDefaultToolbar(transform)}</Toolbar>
    ),
  });

  const { renderDefaultToolbar } =
    defaultLayoutPluginInstance.toolbarPluginInstance;

  const transform = (slot) => {
    const { Zoom } = slot;

    return {
      ...slot,
      Zoom: () => <Zoom levels={[0.4, 0.8, 1.2, 1.6, 2.4, 3.2]} />,
      Download: () => null, // Disable download button
      Print: () => null, // Disable print button
    };
  };

  useEffect(() => {
    if (username) {
      // Fetch the last PDF drawing based on the username
      axios
        .get(
          `${process.env.REACT_APP_SERVER_DOMAIN}/api/drawings/getLastDrawing/${username}`
        )
        .then((response) => {
          if (response.data.drawing) {
            setPdfUrl(response.data.drawing.link); // Set the PDF URL
          } else {
            setError("No Drawing found...");
          }
        })
        .catch((error) => {
          console.error("Error fetching last drawing:", error);
          setError("Error fetching PDF");
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [username]);

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        style={{ height: "100vh" }}
      >
        <CircularProgress size={100} thickness={5} />
        <Typography variant="h6" style={{ marginLeft: "16px" }}>
          Loading PDF...
        </Typography>
      </Box>
    );
  }

  if (error) {
    return <Typography variant="h6">{error}</Typography>;
  }

  if (!pdfUrl) {
    return <Typography variant="h6">No PDF available</Typography>;
  }

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      style={{ height: "100vh", padding: "0px" }}
    >
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          style={{
            border: "1px solid rgba(0, 0, 0, 0.3)",
            overflow: "auto",
            width: "100%",
            height: "100%",
            padding: "10px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            borderRadius: "8px",
          }}
        >
          <Viewer
            fileUrl={pdfUrl} // Use the fetched PDF URL here
            plugins={[defaultLayoutPluginInstance]} // Include the plugin
            renderLoader={(percentages) => (
              <div
                style={{
                  width: "100%",
                  height: "100vh",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress
                  variant="determinate"
                  value={Math.round(percentages)}
                  size={100}
                  thickness={5}
                  style={{ marginBottom: "16px" }}
                />
                <Typography variant="h6">
                  Loading PDF... {Math.round(percentages)}%
                </Typography>
              </div>
            )}
            withCredentials={false}
            theme={{
              theme: "dark", // Apply dark theme
            }}
          />
        </Box>
      </Worker>
    </Box>
  );
}

export default PDFviewer;
