import DashboardScreen from "../features/Portal/Dashboard/DashboardScreen";

function Dashboard() {
  return (
    <div>
      <DashboardScreen />
    </div>
  );
}

export default Dashboard;
