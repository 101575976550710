import { useState, useEffect } from "react";
import axios from "axios";

function Mp4({ userId }) {
  const [animations, setAnimations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (userId) {
      // Fetch animations based on userId
      axios
        .get(
          `${process.env.REACT_APP_SERVER_DOMAIN}/api/animations/${userId}/animations`
        )
        .then((response) => {
          setAnimations(response.data.animations || []); // Ensure animations is an array
        })
        .catch((error) => {
          console.error("Error fetching animations:", error);
          setError("Error fetching animations");
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [userId]);

  if (loading) {
    return <div>Loading animations...</div>;
  }

  if (error) {
    return <div>{error}</div>; // Display the error message
  }

  if (animations.length === 0) {
    return <div>No animations found...</div>;
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column", // Make sure items are stacked vertically
        alignItems: "center", // Center videos horizontally (optional)
        backgroundColor: "#000",
      }}
    >
      {animations.map((animation, index) => (
        <div key={index} style={{ margin: "20px 0", width: "100%" }}>
          <video
            src={animation.link}
            style={{
              width: "80%", // Adjust the width as needed
              height: "auto",
              objectFit: "contain",
            }}
            autoPlay
            loop
            muted
            controls={true}
            controlsList="nodownload"
          />
        </div>
      ))}
    </div>
  );
}

export default Mp4;
