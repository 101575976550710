import About from "../features/Home/components/About"
import { ClientWall } from "../features/Home/components/ClientWall"
import Contact from "../features/Home/components/Contact"
import Footer from "../features/Home/components/Footer"
import Navbar from "../features/Home/components/Navbar"
import ProjectsFrontpage from "../features/Home/components/ProjectsFrontpage"
import Slideshow from "../features/Home/components/Slideshow"

function Home() {
  return (
      <>
         <Navbar/>
        <Slideshow/>
        <About/>
        <ProjectsFrontpage/>
        <ClientWall/>
        <Contact/>
        <Footer/> 
      </>
  )
}

export default Home