import { Box, Typography, CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";

const StatusCard = ({ icon, name, progress, progressColor }) => {
  const [animatedProgress, setAnimatedProgress] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setAnimatedProgress((prev) => {
        const nextValue = prev + 1;
        if (nextValue >= progress) {
          clearInterval(interval); // Stop the interval when progress is reached
        }
        return nextValue;
      });
    }, 100); // Adjust the speed of the animation here

    return () => {
      clearInterval(interval);
    };
  }, [progress]); // Empty dependency array ensures this runs only once

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-evenly"
      padding={"20px 10px"}
      sx={{
        backgroundColor: "#313131",
        borderRadius: "8px",
        color: "white",
        width: "100%",
      }}
    >
      <Box display="flex" alignItems="center">
        <Box>
          <Typography
            variant="h5"
            component="div"
            sx={{ color: "secondary.main" }}
          >
            {`${progress}%`}
          </Typography>
          <Box display={"flex"}>
            {icon}
            <Typography variant="body2" sx={{ color: "primary.main" }}>
              {name}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box position="relative" display="inline-flex">
        <CircularProgress
          variant="determinate"
          size={55}
          value={100}
          sx={{
            color: "grey.700", // Full track color
            borderRadius: "15px",
            size: 80,
          }}
        />
        <CircularProgress
          variant="determinate"
          value={animatedProgress}
          size={55}
          sx={{
            color: progressColor, // Completed progress color
            position: "absolute",
            left: 0,
          }}
        />

        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography variant="caption" component="div" color="white">
            {`${animatedProgress}%`}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default StatusCard;
