import React, { useState } from "react";
import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
  CircularProgress,
  IconButton,
} from "@mui/material";
import axios from "axios";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import EmojiEmotionsIcon from "@mui/icons-material/EmojiEmotions";

const SignUp: React.FC = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState(""); // For error messages
  const [loading, setLoading] = useState(false); // Loading state

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    axios
      .post(`${process.env.REACT_APP_SERVER_DOMAIN}/register`, {
        username,
        password,
      })
      .then((response) => {
        setLoading(false);
        console.log(response.data);
        if (response.data.message) {
          window.location.href = "/login"; // Redirect after success
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        setErrorMessage("Error registering user. Please try again.");
      });
  };

  return (
    <Container
      maxWidth={false}
      sx={{
        minHeight: "100vh",
        width: "100vw",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#2C2C2C", // Dark grey
        backgroundImage: "linear-gradient(135deg, #3E2723 25%, #2C2C2C 100%)", // Brown to dark grey gradient
        backgroundSize: "cover",
        position: "relative",
        overflow: "hidden",
      }}
    >
      {/* Updated Box Style */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: 5,
          backgroundColor: "rgba(50, 50, 50, 0.85)", // Dark overlay with slight transparency
          borderRadius: "16px",
          boxShadow: "0 12px 40px rgba(0, 0, 0, 0.2)",
          border: "2px solid #444", // Darker border
          maxWidth: "100%",
          width: "500px",
          zIndex: 1,
          transition: "transform 0.5s ease-in-out",
          "&:hover": {
            transform: "scale(1.03)",
            boxShadow: "0 16px 50px rgba(0, 0, 0, 0.3)",
          },
        }}
      >
        {/* Icon Button */}
        <IconButton
          color="primary"
          sx={{
            fontSize: 50,
            mb: 2,
            transform: "rotate(0deg)",
            transition: "transform 0.3s ease",
            "&:hover": {
              transform: "rotate(360deg)",
            },
          }}
          disableRipple
        >
          <PersonAddAltIcon fontSize="large" />
        </IconButton>
        <Typography
          variant="h3"
          gutterBottom
          sx={{
            fontFamily: "Poppins, sans-serif",
            color: "#F5F5F5", // Soft white color
          }}
        >
          ZJ - Associates
        </Typography>

        <Typography
          variant="body1"
          sx={{ mb: 3, color: "#B0B0B0", fontFamily: "Poppins, sans-serif" }}
        >
          Create a new account here...
        </Typography>

        {/* Form */}
        <form onSubmit={handleSubmit} style={{ width: "100%" }}>
          {/* Transparent Input Boxes */}
          <TextField
            label="Username"
            variant="outlined"
            fullWidth
            margin="normal"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
            sx={{
              borderRadius: "8px",
              backgroundColor: "transparent",
              color: "#FFF",
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "#B0B0B0",
                },
                "&:hover fieldset": {
                  borderColor: "#FFF",
                },
              },
            }}
          />
          <TextField
            label="Password"
            variant="outlined"
            type="password"
            fullWidth
            margin="normal"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            sx={{
              borderRadius: "8px",
              backgroundColor: "transparent",
              color: "#FFF",
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "#B0B0B0",
                },
                "&:hover fieldset": {
                  borderColor: "#FFF",
                },
              },
            }}
          />

          {/* Error Message */}
          {errorMessage && (
            <Typography variant="body2" color="error" sx={{ mt: 1 }}>
              {errorMessage}
            </Typography>
          )}

          {/* "Sign Up" Button with Updated Hover */}
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            sx={{
              mt: 3,
              backgroundColor: "#5D4037", // Brown color
              color: "#fff",
              padding: "12px 0",
              fontSize: "18px",
              fontWeight: "bold",
              borderRadius: "50px",
              transition: "background-color 0.3s, transform 0.3s ease",
              "&:hover": {
                backgroundColor:
                  "linear-gradient(135deg, #795548 25%, #3E2723 100%)",
                boxShadow: "0 12px 20px rgba(0, 0, 0, 0.3)",
                transform: "translateY(-3px)",
              },
            }}
          >
            {loading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              "Sign Up"
            )}
          </Button>
        </form>
      </Box>
    </Container>
  );
};

export default SignUp;
