import React, { useState } from "react";
import {
  Box,
  Grid,
  IconButton,
  TextField,
  Button,
  Typography,
  InputAdornment,
  Divider,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import ZJ from "../../Home/components/Images/Logos/ZJ.png";
import ZJvid from "./Assets/vid.mp4";
import AccountCircle from "@mui/icons-material/AccountCircle";
import LockIcon from "@mui/icons-material/Lock";
import axios from "axios";
import "./LoginAnimation.css"; // CSS for animation effects

import firstImage from "./1st.jpg";
import secondImage from "./2nd.jpg";

function LoginScreen() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    axios
      .post(`${process.env.REACT_APP_SERVER_DOMAIN}/login`, {
        username,
        password,
      })
      .then((response) => {
        if (response.data.message === "Success") {
          localStorage.setItem(
            "userDetails",
            JSON.stringify(response.data.user)
          );
          setIsLoggedIn(true);

          setTimeout(() => {
            if (username === "admin") {
              navigate("/admin");
            } else {
              navigate("/dashboard");
            }
          }, 3000); // Adjust delay as needed
        } else {
          setErrorMessage(response.data);
        }
      })
      .catch(() => {
        setErrorMessage("Error logging in. Please try again.");
      });
  };

  if (isLoggedIn) {
    return (
      <div className="fullscreen-slideshow">
        <img src={firstImage} alt="First" className="slideshow-image" />
        <img src={secondImage} alt="Second" className="slideshow-image" />
      </div>
    );
  }

  return (
    <div>
      <Grid container>
        <Grid item xs={12} md={8}>
          <Box
            sx={{
              position: "relative",
              height: "100vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "black",
              overflow: "hidden",
            }}
          >
            <IconButton
              size="large"
              edge="start"
              aria-label="logo"
              sx={{
                position: "absolute",
                top: "40%",
                zIndex: 10,
                color: "transparent",
                display: { xs: "none", md: "flex" },
              }}
            >
              <Link to="/">
                <img
                  src={ZJ}
                  alt="ZJ"
                  style={{ width: "90px", height: "90px" }}
                />
              </Link>
            </IconButton>

            <video
              src={ZJvid}
              autoPlay
              loop
              muted
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                position: "absolute",
                top: 0,
                left: 0,
                zIndex: 0,
              }}
            ></video>
          </Box>
        </Grid>

        <Grid
          item
          xs={12}
          md={4}
          sx={{
            backgroundColor: { xs: "rgba(0, 0, 0, 0.4)", md: "black" },
            zIndex: { xs: 20, md: 10 },
            width: "100%",
          }}
        >
          <Box
            sx={{
              height: "100vh",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-start",
              padding: "0 60px",
              color: "white",
            }}
          >
            <Typography variant="h4" gutterBottom color={"primary"}>
              LOGIN
              <Divider
                sx={{
                  width: "40%",
                  borderBottom: "2px solid #C5A47E",
                  marginTop: "16px",
                }}
              />
            </Typography>

            <form onSubmit={handleSubmit} style={{ width: "100%" }}>
              <TextField
                variant="outlined"
                margin="normal"
                placeholder="Username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountCircle color={"secondary"} />
                    </InputAdornment>
                  ),
                  style: { color: "white" },
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "white",
                    },
                    "&:hover fieldset": {
                      borderColor: "white",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "white",
                    },
                  },
                }}
              />

              <TextField
                variant="outlined"
                margin="normal"
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockIcon color={"secondary"} />
                    </InputAdornment>
                  ),
                  style: { color: "white" },
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "white",
                    },
                    "&:hover fieldset": {
                      borderColor: "white",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "white",
                    },
                  },
                }}
              />

              {errorMessage && (
                <Typography variant="body2" color="error">
                  {errorMessage}
                </Typography>
              )}
              <br></br>
              <Button
                type="submit"
                variant="contained"
                size="large"
                sx={{
                  marginTop: 2,
                  backgroundColor: "transparent",
                  color: "white",
                  border: "2px solid white",
                }}
              >
                LOGIN
              </Button>
            </form>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}

export default LoginScreen;
