import { Grid } from "@mui/material";
import AnimationIcon from "@mui/icons-material/Animation";
import DrawIcon from "@mui/icons-material/Draw";
import ArchitectureIcon from "@mui/icons-material/Architecture";
import MovieOutlinedIcon from "@mui/icons-material/MovieOutlined";
import DashboardCard from "./DashboardCard";
import { Link } from "react-router-dom";
const DashboardHeroSection = () => {
  const cardData = [
    {
      title: "ANIMATIONS",
      progress: 28,
      progressColor: "orange",
      Icon: AnimationIcon,
    },
    {
      title: "RENDERS",
      progress: 56,
      progressColor: "blue",
      Icon: DrawIcon,
    },
    {
      title: "DRAWINGS",
      progress: 39,
      progressColor: "purple",
      Icon: ArchitectureIcon,
    },
    {
      title: "PRESENTATION",
      progress: 80,
      progressColor: "green",
      Icon: MovieOutlinedIcon,
    },
  ];
  return (
    <div>
      {" "}
      <Grid container px={2} py={1} display={"flex"} justifyContent={"center"}>
        {cardData.map((card, index) => (
          <Grid item xs={12} sm={6} md={3} key={index} px={2} py={1}>
            <Link
              to={`/${card.title.toLowerCase()}`}
              style={{ textDecoration: "none" }}
            >
              <DashboardCard
                title={card.title}
                progress={card.progress}
                progressColor={card.progressColor}
                Icon={card.Icon}
              />
            </Link>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default DashboardHeroSection;
