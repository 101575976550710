import {
  Box,
  Button,
  Grid,
  Stack,
  TextField,
  Typography,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
  SelectChangeEvent,
} from "@mui/material";
import background from "./Images/1.jpg";
import "./CSS/Contact.css";
import emailjs from "@emailjs/browser";
import { useRef, useState, FormEvent } from "react";

const Contact = () => {
  const [messageSent, setMessageSent] = useState(false);
  const [service, setService] = useState("");
  const form = useRef<HTMLFormElement>(null);

  const sendEmail = (e: FormEvent) => {
    e.preventDefault();

    if (form.current === null) return;

    emailjs
      .sendForm(
        "service_0yrf2nr",
        "template_od1e6p8",
        form.current,
        "KMEBXuMbVzy8gWBFz"
      )
      .then(
        () => {
          console.log("SUCCESS!");
          setMessageSent(true);
          (e.target as HTMLFormElement).reset();

          setTimeout(() => {
            setMessageSent(false);
          }, 2000);
        },
        (error: any) => {
          console.log("FAILED...", error.text);
        }
      );
  };

  const handleServiceChange = (event: SelectChangeEvent<string>) => {
    setService(event.target.value);
  };

  return (
    <Box
      className="contact-background"
      display="flex"
      alignItems="center"
      sx={{
        backgroundImage: `url(${background})`,
        padding: {
          xs: "20px",
          sm: "30px",
          md: "60px",
          lg: "90px",
          xl: "100px",
        },
        height: {
          xs: "100vh",
          sm: "70vh",
          md: "65vh",
          lg: "65vh",
          xl: "65vh",
        },
      }}
    >
      <Stack width="100%">
        <Grid container>
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            display="flex"
            justifyContent="flex-start"
            sx={{
              justifyContent: {
                xs: "center",
                sm: "center",
                md: "flex-start",
              },
            }}
          >
            <Typography
              component="h1"
              color="primary"
              fontWeight={2}
              className="dot"
              sx={{
                fontSize: {
                  xs: "1.4em",
                  sm: "1.7em",
                  md: "1em",
                },
              }}
            >
              CONTACT US
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={8}>
            <Typography
              component="h1"
              color="secondary"
              display="flex"
              fontWeight={500}
              sx={{
                fontSize: {
                  xs: "1.7em",
                  sm: "2em",
                  md: "2.2em",
                  lg: "2.4em",
                  xl: "3em",
                },
                padding: {
                  xs: "20px 0 0 0 ",
                  sm: "30px 0 0 0 ",
                  md: "0 0 0 0 ",
                },
                justifyContent: {
                  xs: "center",
                  sm: "center",
                  md: "flex-start",
                },
              }}
            >
              LET'S DISCUSS YOUR PROJECT
            </Typography>
            <Typography
              component="p"
              color="primary"
              sx={{
                marginTop: {
                  xs: "1.1em",
                  sm: "1.5em",
                  md: "1.7em",
                  lg: "2em",
                  xl: "3em",
                },
                fontSize: {
                  xs: "1em",
                  sm: "1.3em",
                  md: "1.4em",
                  lg: "1.6em",
                  xl: "2em",
                },
                textAlign: {
                  xs: "center",
                  sm: "center",
                  md: "start",
                },
              }}
            >
              Fill out the form and our manager will contact you for
              consultation.
            </Typography>
            <Grid container>
              <form ref={form} onSubmit={sendEmail} className="Contact-form">
                <Grid
                  container
                  xs={12}
                  sx={{
                    gap: {
                      xs: "30px",
                      sm: "0px",
                    },
                  }}
                >
                  <Grid item xs={12} sm={3}>
                    <TextField
                      label="Name"
                      name="from_name"
                      variant="standard"
                      required
                      sx={{
                        width: {
                          xs: "80%",
                          sm: "80%",
                        },
                        "& .MuiInputBase-input": { color: "white" },
                        "& .MuiInputLabel-root": { color: "white" },
                        "& .MuiInputLabel-root.Mui-focused": {
                          color: "#c5a47e",
                        },
                        "& .MuiInput-underline:before": {
                          borderBottomColor: "white",
                        },
                        "& .MuiInput-underline:hover:before": {
                          borderBottomColor: "white",
                        },
                        "& .MuiInput-underline:after": {
                          borderBottomColor: "#c5a47e",
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <TextField
                      label="Phone Number"
                      name="phone"
                      variant="standard"
                      required
                      sx={{
                        width: {
                          xs: "80%",
                          sm: "80%",
                        },
                        "& .MuiInputBase-input": { color: "white" },
                        "& .MuiInputLabel-root": { color: "white" },
                        "& .MuiInputLabel-root.Mui-focused": {
                          color: "#c5a47e",
                        },
                        "& .MuiInput-underline:before": {
                          borderBottomColor: "white",
                        },
                        "& .MuiInput-underline:hover:before": {
                          borderBottomColor: "white",
                        },
                        "& .MuiInput-underline:after": {
                          borderBottomColor: "#c5a47e",
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <FormControl
                      fullWidth
                      sx={{
                        ml: {
                          xs: "10%",
                          md: "0%",
                        },
                      }}
                    >
                      <InputLabel
                        id="service-select-label"
                        sx={{ color: "white" }}
                      >
                        Service
                      </InputLabel>
                      <Select
                        labelId="service-select-label"
                        id="service-select"
                        name="service"
                        value={service}
                        label="Service"
                        onChange={handleServiceChange}
                        required
                        sx={{
                          width: {
                            xs: "80%",
                            sm: "80%",
                          },
                          "& .MuiSvgIcon-root": { color: "white" },
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: "white",
                          },
                          "& .MuiSelect-select": { color: "white" },
                          "&:hover .MuiOutlinedInput-notchedOutline": {
                            borderColor: "white",
                          },
                        }}
                      >
                        <MenuItem value="Commercial">Commercial</MenuItem>
                        <MenuItem value="Landscape">Landscape</MenuItem>
                        <MenuItem value="Urban Design">Urban Design</MenuItem>
                        <MenuItem value="Interior Construction">
                          Interior Construction
                        </MenuItem>
                        <MenuItem value="Residential">Residential</MenuItem>
                        <MenuItem value="Other">Other</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={3} mt="10px">
                    <Button
                      type="submit"
                      variant="contained"
                      color="secondary"
                      size="large"
                      className="CheckButton"
                      style={{
                        color: "white", // Text color
                        borderColor: "white", // Border color
                        borderRadius: "20px", // Rounded corners
                        alignSelf: "flex-end", // Align button to the left
                      }}
                    >
                      Send
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
        </Grid>
      </Stack>
    </Box>
  );
};

export default Contact;
