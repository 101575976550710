import { Box } from "@mui/material";
import MiniDrawer from "../Drawer";
import DashboardMainSection from "../DashboardMainSection";
import ImageViewer from "../ImageViewer/ImageViewer";

const Renders = () => {
  // Fetch username from localStorage
  const userDetails = JSON.parse(localStorage.getItem("userDetails") || "{}");
  const username = userDetails.username || "Guest"; // Fallback to "Guest" if no username is found

  return (
    <Box
      sx={{
        width: "100vw",
        minHeight: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <MiniDrawer CurrentLocation={"RENDERS"} />
      <DashboardMainSection
        CurrentLocation={"Renders"}
        // Pass username to ImageViewer to fetch renders dynamically
        component1={<ImageViewer username={username} />}
      />
    </Box>
  );
};

export default Renders;
