import { Box } from "@mui/material";
import MiniDrawer from "../Drawer";
import DashboardMainSection from "../DashboardMainSection";
import PDFviewer from "../PDF/PDFviewer";

const Drawings = () => {
  // Fetch the username from localStorage or your app context
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const username = userDetails ? userDetails.username : null;

  return (
    <Box
      sx={{
        width: "100vw",
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <MiniDrawer CurrentLocation={"DRAWINGS"} />
      <DashboardMainSection
        CurrentLocation={"Drawings"}
        component1={<PDFviewer username={username} />}  // Pass username to PDFviewer
      />
    </Box>
  );
};

export default Drawings;
