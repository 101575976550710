import LoginScreen from "../features/Portal/Login/LoginScreen"



function Login() {
  return (
    <div>
     <LoginScreen/>
    </div>
  )
}

export default Login