import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import EditUser from "./EditUser";
import "./AdminPage.css";

// Define User and TeamMember interfaces
interface User {
  _id: string;
  username: string;
  ContractPhasePercentage: number;
  DesignPhasePercentage: number;
  FinalizeDesignPercentage: number;
  ConstructionPhasePercentage: number;
}

interface TeamMember {
  name1?: string;
  role1?: string;
  name2?: string;
  role2?: string;
  name3?: string;
  role3?: string;
  name4?: string;
  role4?: string;
}

const AdminPage = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [teamMembers, setTeamMembers] = useState<TeamMember | null>(null);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_SERVER_DOMAIN}/api/users`)
      .then((response) => {
        setUsers(response.data);
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
      });
  }, []);

  const handleUserClick = (userId: string) => {
    axios
      .get(`${process.env.REACT_APP_SERVER_DOMAIN}/api/users/${userId}`)
      .then((response) => {
        setSelectedUser(response.data.user);
        setTeamMembers(response.data.teamMembers);
      })
      .catch((error) => {
        console.error("Error fetching user details:", error);
      });
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const closeEditModal = () => {
    setIsEditing(false);
    setSelectedUser(null);
    setTeamMembers(null);
  };

  return (
    <div className="admin-page">
      <h1>Admin Dashboard</h1>
      <div className="user-container">
        {users.length > 0 ? (
          users.map((user) => (
            <div
              key={user._id}
              className="user-box"
              onClick={() => handleUserClick(user._id)}
            >
              <h3>{user.username}</h3>
            </div>
          ))
        ) : (
          <p>No users found</p>
        )}
      </div>

      {/* User Details Section */}
      {selectedUser && (
        <div className="user-details">
          <h2>User Details</h2>
          <p>
            <strong>Username:</strong> {selectedUser.username}
          </p>
          <p>
            <strong>Contract Phase Percentage:</strong>{" "}
            {selectedUser.ContractPhasePercentage}
          </p>
          <p>
            <strong>Design Phase Percentage:</strong>{" "}
            {selectedUser.DesignPhasePercentage}
          </p>
          <p>
            <strong>Finalize Design Percentage:</strong>{" "}
            {selectedUser.FinalizeDesignPercentage}
          </p>
          <p>
            <strong>Construction Phase Percentage:</strong>{" "}
            {selectedUser.ConstructionPhasePercentage}
          </p>

          <h3>Team Members</h3>
          <div className="team-members">
            {teamMembers ? (
              [1, 2, 3, 4].map((num) => {
                const nameKey = `name${num}` as keyof TeamMember;
                const roleKey = `role${num}` as keyof TeamMember;
                const name = teamMembers[nameKey];
                const role = teamMembers[roleKey];

                // Only display if both name and role are present
                return name && role ? (
                  <div key={num}>
                    <strong>{name}</strong> - {role}
                  </div>
                ) : null;
              })
            ) : (
              <p>No team members assigned.</p>
            )}
          </div>
          <br></br>

          <button onClick={handleEdit}>Edit User</button>
        </div>
      )}

      {isEditing && selectedUser && (
        <EditUser
          user={selectedUser}
          closeModal={closeEditModal}
          refreshUsers={() => setUsers(users)}
        />
      )}

      {/* Plus icon to navigate to the signup page */}
      <Link to="/signup" className="add-user-icon">
        +
      </Link>
    </div>
  );
};

export default AdminPage;
