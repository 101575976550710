import { Box, Typography, Grid } from "@mui/material";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import ProjectTeamCard from "./ProjectTeamCard";

const ProjectTeam = ({ teamMembers }) => {
  return (
    <Box>
      <Grid
        container
        px={2}
        py={1}
        display={"flex"}
        justifyContent={"center"}
      >
        <Grid item xs={12} px={2}>
          <Typography
            variant="h5"
            width="100%"
            textAlign="left"
            gutterBottom
            display="flex"
            alignItems="center"
          >
            <PeopleAltOutlinedIcon
              fontSize="large"
              sx={{ color: `secondary.dark`, mr: 1 }}
            />
            <Box
              component="span"
              sx={{ color: `secondary.dark`, fontWeight: "bold" }}
            >
              PROJECT
            </Box>
            <Box
              component="span"
              sx={{ color: `primary.dark`, marginLeft: 1, fontWeight: "500" }}
            >
              Team
            </Box>
          </Typography>
        </Grid>
        {teamMembers.map((member, index) => (
          <Grid item xs={12} sm={6} md={3} key={index} px={2} py={1}>
            <ProjectTeamCard name={member.name} role={member.role} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default ProjectTeam;
