import React, { useState, useEffect } from "react";
import { PhotoProvider, PhotoView } from "react-photo-view";
import axios from "axios";
import "react-photo-view/dist/react-photo-view.css";

function ImageViewer({ username }) {
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (username) {
      // Fetch all renders for the user based on the username
      axios
        .get(
          `${process.env.REACT_APP_SERVER_DOMAIN}/api/renders/getAllRenders/${username}`
        )
        .then((response) => {
          setImages(response.data.renders || []); // Set the renders in state
        })
        .catch((error) => {
          console.error("Error fetching renders:", error);
          setError("Error fetching renders");
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [username]);

  if (loading) {
    return <div>Loading renders...</div>;
  }

  if (error) {
    return <div>{error}</div>; // Display error message if any
  }

  if (!images.length) {
    return <div>No renders found...</div>;
  }

  return (
    <PhotoProvider>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          overflowY: "auto",
          padding: "0 20px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        }}
      >
        {images.map((render, index) => (
          <PhotoView key={index} src={render.link}>
            <img
              src={render.link}
              alt={`Render ${index + 1}`}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                marginBottom: "20px", // Optional: space between images
                borderRadius: "8px",
              }}
            />
          </PhotoView>
        ))}
      </div>
    </PhotoProvider>
  );
}

export default ImageViewer;
