import React, { useState, useEffect, useRef } from 'react';
import {
  AppBar,
  Toolbar,
  IconButton,
  Button,
  Box,
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Paper
} from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import ZJ from "./Images/Logos/ZJ.png";
import MenuDrawer from "./MenuDrawer";
import "./CSS/Navbar.css";
import { Link } from 'react-router-dom';

const Navbar = () => {
  const [openProjects, setOpenProjects] = useState(false);
  const [openServices, setOpenServices] = useState(false);
  const [openArchitecture, setOpenArchitecture] = useState(false);
  const [scrolling, setScrolling] = useState(false);
  const projectsButtonRef = useRef<HTMLButtonElement>(null);
  const servicesButtonRef = useRef<HTMLButtonElement>(null);
  const [dropdownPositionProjects, setDropdownPositionProjects] = useState({ top: 0, left: 0 });
  const [dropdownPositionServices, setDropdownPositionServices] = useState({ top: 0, left: 0 });

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      setScrolling(scrollTop > 50);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleClickProjects = () => {
    if (projectsButtonRef.current) {
      const rect = projectsButtonRef.current.getBoundingClientRect();
      setDropdownPositionProjects({ top: rect.bottom, left: rect.left });
    }
    setOpenProjects(!openProjects);
    setOpenServices(false);
  };

  const handleClickServices = () => {
    if (servicesButtonRef.current) {
      const rect = servicesButtonRef.current.getBoundingClientRect();
      setDropdownPositionServices({ top: rect.bottom, left: rect.left });
    }
    setOpenServices(!openServices);
    setOpenProjects(false);
    setOpenArchitecture(false);
  };

  const handleLinkClick = (route: string) => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  };

  const handleClickArchitecture = () => {
    setOpenArchitecture((prev) => !prev);
  };

  return (
    <AppBar
      position='fixed'
      sx={{
        height: '100px',
        justifyContent: 'center',
        backgroundColor: scrolling ? '#1b1b1b' : 'transparent',
        boxShadow: 'none',
        borderBottom: '1px solid grey',
        transition: 'background-color 0.3s ease-in-out',
      }}
    >
      <Toolbar>
        <IconButton size='large' edge='start' aria-label="logo" sx={{ mr: 2, color: 'transparent' }}>
             <Link to='/'>
                <img src={ZJ} alt="ZJ" style={{ width: "90px", height: '90px', marginLeft: "20px" }} onClick={() => handleLinkClick('/')} />
             </Link>
        </IconButton>
        <Box sx={{ flexGrow: 1, justifyContent: 'flex-end', display: { xs: "none", sm: "flex", marginRight: '20px' } }}>
          <Link to='/' style={{ textDecoration: 'none' }}>
            <Button color="primary" className="Menu-items" disableRipple onClick={() => handleLinkClick('/')}>Home</Button>
          </Link>
          <Link to='/aboutpage' style={{ textDecoration: 'none' }}>
            <Button color="primary" className="Menu-items" disableRipple onClick={() => handleLinkClick('/aboutpage')}>About</Button>
          </Link>

          <Button
            ref={projectsButtonRef}
            color="primary"
            className="Menu-items"
            onClick={handleClickProjects}
            disableRipple
            endIcon={openProjects ? <ExpandLess /> : <ExpandMore />}
          >
            Projects
          </Button>
          <Collapse in={openProjects} timeout="auto" unmountOnExit>
            <Paper
              sx={{
                position: 'absolute',
                top: dropdownPositionProjects.top,
                left: dropdownPositionProjects.left,
                zIndex: 1,
                mt: 1,
              }}
            >
              <List component="div" disablePadding>
                {['All', 'Completed', 'Ongoing'].map((subItem) => (
                  <Link
                    key={subItem}
                    to='/projectpage'
                    state={subItem}
                    style={{
                      textDecoration: 'none',
                      color: 'inherit'
                    }}
                  >
                    <ListItem sx={{ pl: 2 }}>
                      <ListItemButton>
                        <ListItemText primary={subItem} sx={{
                          '&:hover': {
                            color: '#c5a47e'
                          }
                        }} />
                      </ListItemButton>
                    </ListItem>
                  </Link>
                ))}
              </List>
            </Paper>
          </Collapse>
{/* // ===========================Services================================= */}
<Button
        ref={servicesButtonRef}
        color="primary"
        className="Menu-items"
        onClick={handleClickServices}
        disableRipple
        endIcon={openServices ? <ExpandLess /> : <ExpandMore />}
      >
        Services
      </Button>
      <Collapse in={openServices} timeout="auto" unmountOnExit>
        <Paper
          sx={{
            position: 'absolute',
            top: dropdownPositionServices.top,
            left: dropdownPositionServices.left,
            zIndex: 1,
            mt: 1,
          }}
        >
          <List component="div" disablePadding>
            <ListItem sx={{ pl: 4 }}>
              <ListItemButton onClick={handleClickArchitecture}>
                <ListItemText primary="Architecture" />
                {openArchitecture ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
            </ListItem>
            <Collapse in={openArchitecture} timeout="auto" unmountOnExit>
              <List component="div" disablePadding sx={{ pl: 4 }}>
                {['Residential', 'Commercial'].map((subItem) => (
                  <Link key={subItem} to="/servicepage" state={subItem} style={{ textDecoration: 'none', color: 'inherit' }}>
                    <ListItem sx={{ pl: 2 }}>
                      <ListItemButton>
                        <ListItemText primary={subItem} />
                      </ListItemButton>
                    </ListItem>
                  </Link>
                ))}
              </List>
            </Collapse>
            {['Interior', 'Construction', 'Urban'].map((subItem) => (
              <Link key={subItem} to="/servicepage" state={subItem} style={{ textDecoration: 'none', color: 'inherit' }}>
                <ListItem sx={{ pl: 2 }}>
                  <ListItemButton>
                    <ListItemText primary={subItem} />
                  </ListItemButton>
                </ListItem>
              </Link>
            ))}
          </List>
        </Paper>
      </Collapse>
{/* //================================================careers================================================ */}
          <Link to='/careers' style={{ textDecoration: 'none' }}>
            <Button color="primary" className="Menu-items" disableRipple onClick={() => handleLinkClick('/contactpage')}>CAREERS</Button>
          </Link>
          <Link to='/contactpage' style={{ textDecoration: 'none' }}>
            <Button color="primary" className="Menu-items" disableRipple onClick={() => handleLinkClick('/contactpage')}>Contact</Button>
          </Link>
          <Link to='/login' style={{ textDecoration: 'none' }}>
            <Button color="primary" className="Menu-items" disableRipple onClick={() => handleLinkClick('/login')}>Login</Button>
          </Link>
        </Box>
        <Box sx={{ display: { xs: "flex", sm: "none" }, flexGrow: 1, justifyContent: 'flex-end' }}>
          <MenuDrawer />
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default Navbar;
