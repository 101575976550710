import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import "./App.css";
import { AboutPage } from "./features/Home/components/AboutPage";
import { ContactPage } from "./features/Home/components/ContactPage";
import { ProjectPage } from "./features/Home/components/ProjectPage";
import { ServicePage } from "./features/Home/components/ServicePage";
import Signup from "./pages/Signup";
import Gallery from "./features/Home/components/Gallery";
import { Careers } from "./features/Home/components/Careers";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import AdminPage from "./features/Portal/Admin/AdminPage";
import Animations from "./features/Portal/Dashboard/components/Animations/Animations";
import Renders from "./features/Portal/Dashboard/components/Renders/Renders";
import Drawings from "./features/Portal/Dashboard/components/Drawings/Drawings";
import Presentations from "./features/Portal/Dashboard/components/Presentations/Presentations";
import { useEffect } from "react";

function App() {
  useEffect(() => {
    // Disable right-click (context menu)
    const handleContextMenu = (e: MouseEvent) => {
      e.preventDefault();
    };

    document.addEventListener("contextmenu", handleContextMenu);

    return () => {
      document.removeEventListener("contextmenu", handleContextMenu);
    };
  }, []);

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/AboutPage" element={<AboutPage />} />
          <Route path="/ContactPage" element={<ContactPage />} />
          <Route path="/ProjectPage" element={<ProjectPage />} />
          <Route path="/ServicePage" element={<ServicePage />} />
          <Route path="/Gallery" element={<Gallery />} />
          <Route path="/Careers" element={<Careers />} />
          <Route path="/login" element={<Login />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/admin" element={<AdminPage />} /> {/* Admin route */}
          <Route path="/animations" element={<Animations />} />
          <Route path="/renders" element={<Renders />} />
          <Route path="/drawings" element={<Drawings />} />
          <Route path="/presentation" element={<Presentations />} />
          <Route path="/signup" element={<Signup />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
