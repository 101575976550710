import React, { useState, useEffect } from "react";
import axios from "axios";

const EditUser = ({ user, closeModal, refreshUsers }) => {
  const [username, setUsername] = useState(user.username);
  const [password, setPassword] = useState("");

  const [ContractPhasePercentage, setContractPhasePercentage] = useState(
    user.ContractPhasePercentage
  );
  const [DesignPhasePercentage, setDesignPhasePercentage] = useState(
    user.DesignPhasePercentage
  );
  const [FinalizeDesignPercentage, setFinalizeDesignPercentage] = useState(
    user.FinalizeDesignPercentage
  );
  const [ConstructionPhasePercentage, setConstructionPhasePercentage] =
    useState(user.ConstructionPhasePercentage);

  const [teamMembers, setTeamMembers] = useState({
    name1: "",
    role1: "",
    name2: "",
    role2: "",
    name3: "",
    role3: "",
    name4: "",
    role4: "",
  });

  const [animationFile, setAnimationFile] = useState(null);
  const [drawingFile, setDrawingFile] = useState(null);
  const [renderFile, setRenderFile] = useState(null);
  const [presentationFile, setPresentationFile] = useState(null);

  // Initialize uploaded files with empty arrays as default values
  const [uploadedAnimations, setUploadedAnimations] = useState([]);
  const [uploadedDrawings, setUploadedDrawings] = useState([]);
  const [uploadedRenders, setUploadedRenders] = useState([]);
  const [uploadedPresentations, setUploadedPresentations] = useState([]);

  useEffect(() => {
    if (user._id) {
      axios
        .get(`${process.env.REACT_APP_SERVER_DOMAIN}/api/users/${user._id}`)
        .then((response) => {
          const {
            teamMembers,
            animations = [],
            drawings = [],
            renders = [],
            presentations = [],
          } = response.data;
          if (teamMembers) {
            setTeamMembers(teamMembers);
          }
          setUploadedAnimations(animations);
          setUploadedDrawings(drawings);
          setUploadedRenders(renders);
          setUploadedPresentations(presentations);
        })
        .catch((error) => {
          console.error("Error fetching user details:", error);
        });
    }
  }, [user._id]);

  const handleFileUpload = async (mediaType, file) => {
    if (file) {
      const formData = new FormData();
      formData.append("media", file);

      try {
        const res = await axios.post(
          `${process.env.REACT_APP_SERVER_DOMAIN}/api/${mediaType}/${user._id}`,
          formData,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );
        return res.data.message;
      } catch (error) {
        console.error(`Error uploading ${mediaType}:`, error);
        return `Error uploading ${mediaType}`;
      }
    }
  };

  const handleDeleteFile = async (mediaType, fileId) => {
    try {
      // Ensure the correct route structure for each media type
      await axios.delete(
        `${process.env.REACT_APP_SERVER_DOMAIN}/api/${mediaType}/${user._id}/file/${fileId}`
      );

      alert(`${mediaType} file deleted successfully.`);

      // Refresh uploaded files after deletion
      const updatedFiles = await axios.get(
        `${process.env.REACT_APP_SERVER_DOMAIN}/api/users/${user._id}`
      );
      setUploadedAnimations(updatedFiles.data.animations || []);
      setUploadedDrawings(updatedFiles.data.drawings || []);
      setUploadedRenders(updatedFiles.data.renders || []);
      setUploadedPresentations(updatedFiles.data.presentations || []);
    } catch (error) {
      console.error(`Error deleting ${mediaType} file:`, error);
      alert(`Error deleting ${mediaType} file.`);
    }
  };

  const handleDeleteUser = async () => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_SERVER_DOMAIN}/api/users/${user._id}`
      );
      alert("User deleted successfully.");
      refreshUsers();
      window.location.reload();
    } catch (error) {
      console.error("Error deleting user:", error);
      alert("Error deleting user.");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const updatedUser = {
      username,
      ContractPhasePercentage,
      DesignPhasePercentage,
      FinalizeDesignPercentage,
      ConstructionPhasePercentage,
    };

    if (password) {
      updatedUser.password = password;
    }

    try {
      await axios.put(
        `${process.env.REACT_APP_SERVER_DOMAIN}/api/users/${user._id}`,
        updatedUser
      );
      await axios.put(
        `${process.env.REACT_APP_SERVER_DOMAIN}/api/users/${user._id}/teamMembers`,
        teamMembers
      );

      if (animationFile) await handleFileUpload("animations", animationFile);
      if (drawingFile) await handleFileUpload("drawings", drawingFile);
      if (renderFile) await handleFileUpload("renders", renderFile);
      if (presentationFile)
        await handleFileUpload("presentations", presentationFile);

      refreshUsers();
      closeModal();

      // Reload the page after saving
      window.location.reload();
    } catch (err) {
      console.error("Error updating user or media:", err);
    }
  };

  const getFileName = (link) => link.split("/").pop();

  return (
    <form onSubmit={handleSubmit}>
      <label>Username:</label>
      <input value={username} onChange={(e) => setUsername(e.target.value)} />
      <br />
      <label>Password (optional):</label>
      <input
        value={password}
        type="password"
        onChange={(e) => setPassword(e.target.value)}
        placeholder="Enter new password"
      />
      <br />
      <br />

      <label>Contract Phase Percentage:</label>
      <input
        value={ContractPhasePercentage}
        onChange={(e) => setContractPhasePercentage(e.target.value)}
      />
      <br />
      <label>Design Phase Percentage:</label>
      <input
        value={DesignPhasePercentage}
        onChange={(e) => setDesignPhasePercentage(e.target.value)}
      />
      <br />
      <label>Finalize Design Percentage:</label>
      <input
        value={FinalizeDesignPercentage}
        onChange={(e) => setFinalizeDesignPercentage(e.target.value)}
      />
      <br />
      <label>Construction Phase Percentage:</label>
      <input
        value={ConstructionPhasePercentage}
        onChange={(e) => setConstructionPhasePercentage(e.target.value)}
      />
      <br />

      <h3>Team Members</h3>
      {[1, 2, 3, 4].map((num) => (
        <div key={num}>
          <label>Team Member {num} Name:</label>
          <input
            value={teamMembers[`name${num}`]}
            onChange={(e) =>
              setTeamMembers({ ...teamMembers, [`name${num}`]: e.target.value })
            }
          />
          <label>Role {num}:</label>
          <input
            value={teamMembers[`role${num}`]}
            onChange={(e) =>
              setTeamMembers({ ...teamMembers, [`role${num}`]: e.target.value })
            }
          />
        </div>
      ))}
      <br />

      <h3>Uploaded Media Files</h3>
      <div>
        <h4>Animations:</h4>
        {uploadedAnimations.length > 0 ? (
          uploadedAnimations.map((animation) => (
            <div key={animation._id}>
              {getFileName(animation.link)}
              <button
                onClick={() => handleDeleteFile("animations", animation._id)}
              >
                -
              </button>
            </div>
          ))
        ) : (
          <p>No animations uploaded.</p>
        )}
      </div>

      <div>
        <h4>Drawings:</h4>
        {uploadedDrawings.length > 0 ? (
          uploadedDrawings.map((drawing) => (
            <div key={drawing._id}>
              {getFileName(drawing.link)}
              <button onClick={() => handleDeleteFile("drawings", drawing._id)}>
                -
              </button>
            </div>
          ))
        ) : (
          <p>No drawings uploaded.</p>
        )}
      </div>

      <div>
        <h4>Renders:</h4>
        {uploadedRenders.length > 0 ? (
          uploadedRenders.map((render) => (
            <div key={render._id}>
              {getFileName(render.link)}
              <button onClick={() => handleDeleteFile("renders", render._id)}>
                -
              </button>
            </div>
          ))
        ) : (
          <p>No renders uploaded.</p>
        )}
      </div>

      <div>
        <h4>Presentations:</h4>
        {uploadedPresentations.length > 0 ? (
          uploadedPresentations.map((presentation) => (
            <div key={presentation._id}>
              {getFileName(presentation.link)}
              <button
                onClick={() =>
                  handleDeleteFile("presentations", presentation._id)
                }
              >
                -
              </button>
            </div>
          ))
        ) : (
          <p>No presentations uploaded.</p>
        )}
      </div>

      <h3>Upload Media</h3>
      <div>
        <label>Upload Animation (Video):</label>
        <input
          type="file"
          accept="video/*"
          onChange={(e) => setAnimationFile(e.target.files[0])}
        />
      </div>
      <br />

      <div>
        <label>Upload Drawing (PDF):</label>
        <input
          type="file"
          accept="application/pdf"
          onChange={(e) => setDrawingFile(e.target.files[0])}
        />
      </div>
      <br />

      <div>
        <label>Upload Render (Images):</label>
        <input
          type="file"
          accept="image/*"
          onChange={(e) => setRenderFile(e.target.files[0])}
        />
      </div>
      <br />

      <div>
        <label>Upload Presentation (PDF):</label>
        <input
          type="file"
          accept="application/pdf"
          onChange={(e) => setPresentationFile(e.target.files[0])}
        />
      </div>
      <br />

      <button type="submit">Save</button>
      <br />
      <br />
      <button type="button" onClick={closeModal}>
        Cancel
      </button>
      <br />
      <br />
      <button
        type="button"
        onClick={handleDeleteUser}
        style={{ backgroundColor: "red", color: "white" }}
      >
        Delete User
      </button>
    </form>
  );
};

export default EditUser;
