import { Box, Typography, Avatar } from "@mui/material";

const ProjectTeamCard = ({ name, role, PersonImage }) => {
  function stringAvatar(name) {
    if (!name) return { children: "?" };
    const nameParts = name.split(" ");
    const initials = nameParts.length >= 2
      ? `${nameParts[0][0]}${nameParts[1][0]}`
      : nameParts[0][0];

    return {
      sx: {
        bgcolor: "secondary.dark",
      },
      children: initials,
    };
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-evenly"
      padding={"20px 10px"}
      sx={{
        backgroundColor: "#313131",
        borderRadius: "8px",
        color: "white",
        width: "100%",
      }}
    >
      <Box display="flex" alignItems="center">
        <Box>
          <Typography
            variant="h5"
            component="div"
            sx={{ color: "secondary.main" }}
          >
            {name}
          </Typography>

          <Typography variant="body2" sx={{ color: "primary.main" }}>
            {role}
          </Typography>
        </Box>
      </Box>
      <Box>
        <Avatar {...stringAvatar(name)} />
      </Box>
    </Box>
  );
};

export default ProjectTeamCard;
