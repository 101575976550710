import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import MiniDrawer from "../Drawer";
import DashboardMainSection from "../DashboardMainSection";
import Mp4 from "../MP4/Mp4";
import axios from "axios";

const Animations = () => {
  const [userId, setUserId] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  // Get username from localStorage
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const username = userDetails ? userDetails.username : null;

  useEffect(() => {
    if (username) {
      // Fetch userId from username
      axios
        .get(
          `${process.env.REACT_APP_SERVER_DOMAIN}/api/users/getUserId/${username}`
        )
        .then((response) => {
          setUserId(response.data.userId); // Set userId to state
        })
        .catch((error) => {
          console.error("Error fetching userId:", error);
          setErrorMessage("Error fetching userId. Please try again.");
        });
    }
  }, [username]);

  return (
    <Box
      sx={{
        width: "100vw",
        maxHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <MiniDrawer CurrentLocation={"ANIMATIONS"} />
      <DashboardMainSection
        CurrentLocation={"Animations"}
        // Only render Mp4 component if userId is available
        component1={
          userId ? <Mp4 userId={userId} /> : <div>{errorMessage}</div>
        }
      />
    </Box>
  );
};

export default Animations;
