import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#ffffff",
    },
    secondary: {
      main: "#C5A47E",
    },
  },
  typography: {
    fontFamily: "Barlow",
  },
  components: {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: "#1b1b1b",
          // backgroundColor: "rgb(51, 51, 51)",
          color: "#8C8C8C",
        },
      },
    },
  },
});

export default theme;
